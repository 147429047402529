import React from 'react';
import { smallButtonStyle } from './styling';

export const ClickOnceButton = (props) => {
  const [clicked, setClicked] = React.useState(false);

  // Re-enable after 15 seconds if no change in state from server
  React.useEffect(() => {
    let myInterval = setInterval(() => setClicked(false), 15000);
    return () => clearInterval(myInterval);
  });

  return <button style={{ ...smallButtonStyle, minWidth: "10vw" }} disabled={clicked} onClick={() => { setClicked(true); props.onClick(); }}>{props.text}</button>;
};
