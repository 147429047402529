import React from 'react';

import {Marker, Popup, } from 'react-leaflet'
import Leaflet from "leaflet";
import { deviceColor, selectColor } from './styling';
import { ClickOnceButton } from './ClickOnceButton';

const headerSize = "2vmin";

const makeMarkerHtmlStyles = (color, opacity, selected) => `
  background-color: ${color};
  opacity: ${opacity};
  width: ${"1.8rem"};
  height: ${"1.8rem"};
  display: block;
  left: ${"-0.9rem"};
  top:  ${"-0.9rem"};
  position: relative;
  border-radius: ${"1rem 1rem 0"};
  transform: rotate(45deg);
  border: 1px solid #000000`



const makeCenterHtmlStyles = (bAlarm, bChecked, selected) => `
  width: ${".8rem"};
  height: ${".8rem"};
  display: block;
  left: ${"-.4rem"};
  top: ${"-2.3rem"};
  position: relative;
  border-radius: ${".5rem .5rem .5rem .5rem"};
  transform: rotate(45deg);
  border: 1px solid #000000`

const divStyle = `
  width: 10rem;
  position: relative;
  top: -1.5rem;
  color: #880000;
  font-weight: 500;
  text-align: center;
  left: -5rem;
`

const makeMarkerIcon = (name, color, opacity, alarm, checked, selected) => {
  const icon = Leaflet.divIcon({
    className: "lfndr-custom-pin",
    iconAnchor: [0, 24],
    labelAnchor: [-6, 0],
    popupAnchor: [2, -30],
    html:`<div>
            <span style="${makeMarkerHtmlStyles(color, opacity, selected)}"></span>
            <span class="${alarm?"alarm":(checked ? "checked" : "normal")}" style="${makeCenterHtmlStyles(alarm, checked, selected)}"></span>
            <div style="${divStyle}">${name}</div>
          </div>`            
  });
  return icon;
}

const zoomFactorsPx = {
    18:7,
    17:3,
    16:1.2,
    15:0.4,
    }

const makeAccuracyHtmlStyles = (color, opacity, selected, accuracy, size) => `
    background-color: ${color};
    opacity: ${opacity};
    width: ${"1rem"};
    height: ${"1rem"};
    display: block;
    border-radius: ${`${size+0.5}rem ${size+0.5}rem ${size+0.5}rem ${size+0.5}rem`};
    left: ${-size-0.5+"rem"};
    top:  ${-size-0.5+"rem"};
    position: relative;
    transform: rotate(45deg);
    border: ${(size)+"rem"} solid ${"#aaa4"}`
  

const makeAccuracyIcon = (color, opacity, selected, accuracy, zoom, showAccuracy) => {
    if (!(accuracy && showAccuracy))
        accuracy = 1;
    const size = zoomFactorsPx[zoom] ? zoomFactorsPx[zoom]*accuracy/100 /* 40 is accurate scale */ : 0;
    const icon = Leaflet.divIcon({
        className: "lfndr-custom-pin-"+zoom,
        iconAnchor: [size+"rem", size+"rem"],
        labelAnchor: [0, 0],
        popupAnchor: [0, -size],
        html:`<div>
                <span style="${makeAccuracyHtmlStyles(color, opacity, selected, accuracy, size)}"></span>
              </div>`            
      });
      return icon;
}

const roundNdec = (x, k) => {
  return Math.round(x*k)/k;
}

const DeviceMarker = ({ position, map, title, text, timestamp, rssi, opacity, positionTime, alarmTime, checkedTime, color, accuracy, positionType, point, selected, zindex, showAccuracy}) => {
  const [refReady, setRefReady] = React.useState(false);
  let popupRef = React.useRef();

  React.useEffect(() => {
    if (refReady && selected) {
      popupRef.current.openPopup();
    }
  }, [selected, refReady, map]);

  return (
    <Marker key={point.id} map={map} position={position} icon={makeMarkerIcon(title, color, opacity, alarmTime ? true : false, checkedTime ? true:false, selected)} zIndexOffset={zindex*100+1000}>
      <Popup
        key={point.id} 
        style={{width:"40vw", maxWidth:"40vw"}}
        ref={(r) => {
          popupRef.current = r;
          setRefReady(true);
        }}
      >
        <div style={{fontSize:headerSize, fontWeight:600,}}>{title}</div>
        <hr/>
        {text}
        <hr/>
        { alarmTime ? ("Alarm at: " + new Date(alarmTime).toLocaleString()): "No alarm signal"}
        <br/>
        Last position: {new Date(positionTime).toLocaleString()}
        <br/>
        {positionType && <div>Position source: {positionType}</div>}
        Last signal: {new Date(timestamp).toLocaleString()} ({rssi}dB)
        <hr/>
        Application: {point.deviceType}<br/>
        {point.output ? (isFinite(point.output.temp) ? "Temperature: "+roundNdec(point.output.temp, 10)+"°C " : "") : ""} 
        {point.output ? (isFinite(point.output.volts) ? "Voltage: "+roundNdec(point.output.volts, 10)+"V " : "") : ""}
        {(point.output && alarmTime) && <hr></hr>}
        {(point.output && alarmTime) && <div>Alarm</div>}
        <div style={{display: "flex", }}>
        {(point.output && point.onAck  && (!point.output.alarmAck)) && (!point.alarmAcknowledge) && <ClickOnceButton onClick={()=>point.onAck()} text="Acknowledge" />}
        {(point.output && point.alarmAcknowledge) ? <span>Alarm acknowledged by {point.alarmAcknowledge.username} at {new Date(point.alarmAcknowledge.timestamp).toLocaleString()}</span> : <div/>}
        {(point.output && point.output.alarmAck) ? (<div style={{fontWeight:600}}>Alarm Acknowledged</div>) : null}
        {point.onDis ? <ClickOnceButton onClick={()=>point.onDis()} text="Reject" /> : <div/>}
        </div>
        {accuracy ? <hr/>:<div/>}
        {accuracy ? <ClickOnceButton onClick={()=>point.onSelect()} text="Trace" /> : <div/> }
      </Popup>
    </Marker>
  );
};


export const produceDeviceMarkers = (map, series, selected, nowS, ageForMaxOpacityH, opacityScale, maxOpacity, showAccuracy) => {

  series = series.sort((a,b) => {
    if (a.positionTime && b.positionTime) 
      return new Date(a.positionTime).getTime() - new Date(b.positionTime).getTime();
    if (a.positionTime)
      return 1;
    else
      return -1;
  });

  return series.map((point, i) => {
    const ageS = point.positionTime ? nowS - new Date(point.positionTime).getTime()/1000 : 0;
    let ageH = ageS/3600.0;
    if (ageH>ageForMaxOpacityH)
      ageH = ageForMaxOpacityH;
    const agingFactor = ageH/ageForMaxOpacityH*opacityScale;
    const opacity = maxOpacity-agingFactor;
    let lat = 0;
    let lng = 0;
    if (point.latlng && point.latlng.length===2) {
      lat = point.latlng[0];
      lng = point.latlng[1];
    }

    let color = point.id === selected ? selectColor : deviceColor;

    return <DeviceMarker key={point.id} zindex={i} map={map} active={point.active} opacity={opacity} color={color} rssi={point.rssi}
            position={[lat, lng]} accuracy={point.accuracy} positionType={point.positionType} title={point.title} text={point.text} positionTime={new Date(point.positionTime)} 
            alarmTime={point.alarmTime ? new Date(point.alarmTime) : undefined} checkedTime={point.checkedTime?new Date(point.checkedTime):undefined} timestamp={new Date(point.timestamp)} point={point} 
            selected={point.id === selected} showAccuracy={showAccuracy}/>
    })
}


const SolutionMarker = ({ position, map, title, opacity, color, point, selected, zoom, zindex,showAccuracy }) => {
    const [refReady, setRefReady] = React.useState(false);
    let popupRef = React.useRef();
  
    React.useEffect(() => {
      if (refReady && selected) {
        popupRef.current.openPopup();
      }
    }, [selected, refReady, map]);
  
    return (
      <Marker key={point.id} map={map} position={position} zIndexOffset={zindex+1000} icon={makeAccuracyIcon(color, opacity, selected, point.accuracy, zoom, showAccuracy)}>
        <Popup
          key={point.id} 
          ref={(r) => {
            popupRef.current = r;
            setRefReady(true);
          }}
        >
          <div style={{fontSize:headerSize, fontWeight:600,}}>{title}</div>
          <hr/>
          Processed at: {new Date(point.addedTimestamp).toLocaleString()}
          <br/>
          Accuracy: {point.accuracy} m
          <br />
          Status: {point.status}
        </Popup>
      </Marker>
    );
  };

export const produceSolutionMarkers = (map, series, selected, nowS, ageForMaxOpacityH, opacityScale, maxOpacity, zoom, showAccuracy) => {
    return series.map((point, i) => {
        const ageS = point.timestamp ? nowS - new Date(point.timestamp).getTime()/1000 : 0;
        let ageH = ageS/3600.0;
        if (ageH>ageForMaxOpacityH)
          ageH = ageForMaxOpacityH;
        const agingFactor = ageH/ageForMaxOpacityH*opacityScale;
        const opacity = maxOpacity-agingFactor;
        let lat = point.lat;
        let lng = point.lng;
    
        let color = point.id === selected ? selectColor : deviceColor;
    
        return <SolutionMarker key={i} map={map} opacity={opacity} color={color} 
                position={[lat, lng]} accuracy={point.accuracy} showAccuracy={showAccuracy} status={point.text} title={point.title} text={point.text} 
                timestamp={new Date(point.timestamp)} point={point} selected={point.id === selected} zoom={zoom} zindex={i}/>
        })
    
}