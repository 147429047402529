// Copyright (C) 2023, Rutio AB. All rights reserved

export const isDebug = () => {
    return window.location.host.includes("localhost") || window.location.host.includes("192.168");
}  

export const getApiPath = () => {
    const debug = isDebug();
    return (debug ? 
        (window.location.hostname + ":39523") 
        : (window.location.hostname + "/lfndr-api"));
  }
  
  
export const getApi = () => {
    const debug = isDebug();
    return (debug ? "http://" : "https://") + getApiPath();
  }
  
export const getWsApi = () => {
    const debug = isDebug();
    return (debug ? "ws://" : "wss://") + (debug ? 
      (window.location.hostname + ":39523") 
      : (window.location.hostname + ":35369"));
}
  