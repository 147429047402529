// Copyright (C) 2022, Rutio AB, All rights reserved

import React from 'react';

import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet'

export const TimeSpace = (props) => {
  const height = props.height?props.height:"30vmin";
  const width = props.width?props.width:"100vw";
  const top = props.top ? props.top : 0;
  let series = props.series ? props.series : [];
  const nowS = new Date().getTime()/1000;
  let ageForMaxOpacityH = 14*24;
  const maxOpacity = 1.0;
  const opacityScale = 0.6; // how much does opacity scale
  const zoom = props.zoom ? props.zoom : 13;
  const setZoom = props.setZoom ? props.setZoom : ()=>{};
  const markerFactory = props.markerFactory;
  const waitForPos = props.waitForPos;
  const showAccuracy = props.showAccuracy ? true : false;

  const [map, _setMap] = React.useState();
  const setMap = (m) => { _setMap(m); console.log("Saved map in state");}
  let filtered = [];
  series.map(item=>{
    if (item.timestamp && Number.isFinite(item.lat) && Number.isFinite(item.lng))
      filtered.push(item);
    else
      console.log("Item with title " + item.title + " has no position");
    return 0;
  });
  series = filtered;

  if (series.length === 0) {
    return <div style={{height, width,left:0, background:"#eee", fontSize:"2vmin"}}>
      <div style={{position:"fixed", left:"40%", top:"40%"}}>No device positions to map</div></div>
  }

  if (waitForPos) {
    return <div style={{height, width,left:0, background:"#eee", fontSize:"2vmin"}}>
      <div style={{position:"fixed", left:"40%", top:"40%"}}>Waiting for position...</div></div>
  }


  const center = props.center ? props.center : (series.length > 0 ? [series[series.length-1].lat,series[series.length-1].lng] : [0,0]);
  console.log("Map Center:", center);

  const MyZoomListener = () => {
    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoom(mapEvents.getZoom());
            console.log("New zoom level:", zoom);
      },
    });
    return null
  }

  let oldestS = new Date(series[0].positionTime).getTime()/1000;
  let newestS = oldestS;
  series.map(item => {
    let s = new Date(item.positionTime).getTime()/1000; 
    if (s<oldestS) oldestS=s;
    if (s>newestS) newestS=s;
    return 0;
  })

  ageForMaxOpacityH = Number.isInteger(props.maxAge) ? (props.maxAge/3600.0) : ((nowS - oldestS)/3600.0);
  if (ageForMaxOpacityH < 0.1)
    ageForMaxOpacityH = 0.1; // Do not fade 2 seconds old items so much

  return <div style={{fontSize:"3vmin", height, width, top, left:0 }}>
  {0 === series.length && "No positions yet"}
  {0 !== series.length && <MapContainer 
                            style={{height, width, left:0, align:"center"}} 
                            center={center} zoom={zoom} whenReady={setMap} zoomControl={false}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      // maxZoom={20}
    />
    {map && markerFactory(map, series, props.selected, nowS, ageForMaxOpacityH, opacityScale, maxOpacity, zoom, showAccuracy)}
    <MyZoomListener/>
  </MapContainer>}
  </div>
}
