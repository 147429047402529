import React from 'react';
import {fetchStyle} from './styling';
import {isDebug} from './api';

export const Fetch = (props) => {
    const [result, setResult] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const {api, submit} = props;
  
    React.useEffect(()=>{
      let cancelled = false;
      if (!submit)
        return ()=>cancelled=true;
      if (result || error)
        return ()=>cancelled=true;
      const f = async () => {
        try {
          if (cancelled)
            return;
          if (loading)
            return;
          console.log("Fetching: ", props);
          let fetched = await fetch(api);
          let json = await fetched.json();
          if (!cancelled) {
            console.log (json);
            if (json.error) {
              setError(json.error);
              setLoading(()=>false);
              props.onError(json.error);
            } else {
              setResult(json);
              setLoading(()=>false);
              props.onResult(json);
            }
          }
        } catch (e) {
          if (!cancelled) {
            console.log(e.message);
            setError(e);
            setLoading(()=>false);
            props.onError(e);
          }
        }
      }
      setLoading(true);
      setCount(()=>count+1);
      setError(null);
      setResult(null);
      f();
      return ()=>cancelled = true;
    }, [submit]);
    if (result)
      return <div/>;
    if (!submit)
      return <div/>;
    if (!isDebug())
      return loading ? <div style={fetchStyle}>Loading...</div> : <div/>;
    if (loading)
      return <div style={fetchStyle}>
        {count}: Fetch {api} - Loading...
      </div>;
    return <div style={fetchStyle}>
      {count}: Fetch {api} - Error: {error ? "present" : "null"} Result: {result ? "present" : "null"}
    </div>
  }
  