export const formatAge = (s) => {
  if (s < -60)
    return "in future";
  if (s < 60)
    return "just now";
  s = s / 60;
  if (s < 60)
    return Math.floor(s + 0.5) + " minutes";
  s = s / 60;
  if (s < 24)
    return Math.floor(s + 0.5) + " hours";
  s = s / 24;
  if (s < 24)
    return Math.floor(s + 0.5) + " days";
  s = s / 30;
  if (s < 12)
    return Math.floor(s + 0.5) + " months";
  s /= 12;
  if (s < 40)
    return Math.floor(s + 0.5) + " years";
  return "-";
}

export const formatDateAsAge = (d) => {
  if (d instanceof Date) {
    let now = new Date();
    return formatAge((now.getTime() - d.getTime()) / 1000);
  } else {
    return "-";
  }
}
