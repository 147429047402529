
export const backgroundColor = "#eee2";
export const widgetBorderColor = "#ccc";
export const deviceColor = "#68f";
export const alarmColor = "#f33";
export const checkColor = "#4b4";
export const selectColor = "#f77";
export const checkpointColor = "#181";
export const fontSize = "3vmin";
export const smallFontSize = "2vmin";
export const widgetRadius = ".5vmin";
export const buttonMinWidth = "20vw";
export const smallTextStyle = {fontSize:"2vmin", color:"#555", }
export const widgetStyle = {background:backgroundColor, fontSize:fontSize, margin:"1vmin", borderColor:widgetBorderColor};
export const buttonStyle = {...widgetStyle, minWidth:buttonMinWidth, padding:"1vmin", borderRadius:widgetRadius, };
export const smallButtonStyle = {...buttonStyle, fontSize:smallFontSize, padding:"0.5vmin", margin:"0.5vmin"}
export const fetchStyle = {position:'fixed', right:"2vw", bottom:"2vh", fontSize:"1.3vmin"};
export const filterStyle = {...widgetStyle, borderRadius:widgetRadius,};

